/** @jsx jsx */
import { jsx } from "theme-ui";

const HeroButton = ({ parallaxRef }) => {
  return (
    <span
      onClick={() => {
        parallaxRef.current.scrollTo(1);
      }}
      sx={{
        display: `inline-block`,
        fontWeight: `bold`,
        cursor: "pointer",
        borderRadius: 8,
        padding: 16,
        backgroundColor: "grey",
        transition: `all cubic-bezier(0.19, 1, 0.22, 1) 0.6s`,
        "&:hover": {
          color: `white !important`,
          transform: `translateY(-5px)`,
          boxShadow: `xl`,
          backgroundColor: "red",
        },
      }}
    >
      Find out why!
    </span>
  );
};

export default HeroButton;
