import React, { useRef } from "react";
import { Parallax, IParallax } from "@react-spring/parallax";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Hero from "../components/hero";
import Projects from "../components/projects";
import About from "../components/about";
import Contact from "../components/contact";
import { useDesktop } from "../../../hooks";

const Cara = () => {
  const parallaxRef = useRef<IParallax>();
  const isDesktop = useDesktop();

  return (
    <Layout>
      <Parallax pages={isDesktop ? 5 : 7} ref={parallaxRef}>
        <Hero offset={0} factor={1} parallaxRef={parallaxRef} />
        <Projects
          offset={isDesktop ? 1 : 2}
          factor={2}
          parallaxRef={parallaxRef}
        />
        <About
          offset={isDesktop ? 3 : 5}
          factor={1}
          parallaxRef={parallaxRef}
        />
        <Contact
          offset={isDesktop ? 4 : 6}
          factor={1}
          parallaxRef={parallaxRef}
        />
      </Parallax>
    </Layout>
  );
};

export default Cara;
