/** @jsx jsx */
import { Box, useColorMode, jsx } from "theme-ui";

import { bounce } from "../../../styles/animations";

const Footer = ({ parallaxRef }) => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <Box
      as="footer"
      variant="footer"
      sx={{
        width: `100%`,
      }}
    >
      <div
        sx={{
          display: `flex`,
          width: `100%`,
          justifyContent: `space-between`,
          alignItems: `center`,
          px: [10, 10, 50],
          mb: 3,
        }}
      >
        <div
          sx={{
            height: [6, 6, 8],
            width: [6, 6, 8],
          }}
        />
        <button
          sx={{
            variant: `buttons.toggle`,
            fontWeight: `semibold`,
            display: `block`,
            mx: `auto`,
          }}
          onClick={toggleColorMode}
          type="button"
          aria-label="Toggle dark mode"
        >
          {isDark ? `Light` : `Dark`}
        </button>
        <span
          onClick={() => {
            parallaxRef.current.scrollTo(0);
          }}
          sx={{
            display: `flex`,
            backgroundColor: `white`,
            height: [6, 6, 8],
            width: [6, 6, 8],
            borderRadius: 50,
            alignItems: `center`,
            justifyContent: `center`,
            boxShadow: `lg`,
            animation: `${bounce(25)} 2s infinite`,
            cursor: `pointer`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            sx={{
              height: [4, 4, 6],
              width: [4, 4, 6],
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </span>
      </div>
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
    </Box>
  );
};

export default Footer;
