import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ProjectCard id={1} title="Floating Bridge Game" subtitle="Solo Developer" link="https://game.floatingbridge.me/" filename="https://d2r6bsxee7k3xn.cloudfront.net/images/floating-bridge.mp4" bg="linear-gradient(to right, #498467 0%, #52B788 100%)" mdxType="ProjectCard">
  This project is my proudest one to date. Built with React Native, Node,
  Express and MongoDB, what started out as a pet project for my friends became a
  production ready, fully deployed web game. The game server is hosted on
  Digital Ocean, frontend on AWS Amplify, and game assets are served with AWS
  CloudFront CDN. This project pushed my systems and architecture design
  concepts to the limits.
    </ProjectCard>
    <ProjectCard id={2} title="SingHealth Project" subtitle="Lead Full-Stack Developer" link="https://esc-group-10.netlify.app/" filename="singhealth-project.png" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Clinched first place in the 2021 SingHealth Hackathon. This is a Retail
  Management System which aimed to expedite the audit processes at SingHealth,
  built with React Native, Flask and MongoDB.
    </ProjectCard>
    <ProjectCard id={3} title="Beever" subtitle="Lead Android Developer" link="https://play.google.com/store/apps/details?id=tech.cheekit.beever" filename="beever.jpg" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Coming in second place in the Mitsubishi Corp Merit Awards, Beever is an
  Android application that ensures that your meetings will never clash again!
  It's flagship feature is known as the "Gap Finder", which searches through the
  database and suggests the best meeting times.
    </ProjectCard>
    <ProjectCard id={4} title="Electric Vehicle Dashboard" subtitle="Mobile Application Developer" link="https://github.com/thespacemanatee/evam-dashboard" filename="evam-dashboard.jpg" bg="linear-gradient(to right, #097679 0%, #00B5FF 100%)" mdxType="ProjectCard">
  Developed a beautiful dashboard for a 3D printed electric vehicle using React
  Native and C++ on the Arduino, communicating via Bluetooth Low Energy (BLE)
  technology. Used Reanimated 2 to animate SVGs extensively to create a fluid
  yet functional interface with a wow-factor.
    </ProjectCard>
    <ProjectCard id={5} title="Hikari Prints" subtitle="Co-founder" link="https://shop.hikariprints.com/" filename="hikariprints.jpg" bg="linear-gradient(to right, #CF995F 0%, #DFD5A5 100%)" mdxType="ProjectCard">
  Hikari Prints is the brain child between my passion for programming and
  additive manufacturing. I started this business because I discovered a gap in
  the market between 3D printed products and the general consumer.
    </ProjectCard>
    <ProjectCard id={6} title="Chess with React Native" subtitle="Solo Developer" link="https://expo.io/@thespacemanatee/projects/react-native-chess" filename="react-native-chess.png" bg="linear-gradient(to right, #4B0979 0%, #00BFFF 100%)" mdxType="ProjectCard">
  I built a Chess.com clone using React Native with TypeScript. This is also my
  first foray into animations in app development, using Reanimated 2. Fun fact:
  the game played on the right is the famous Fischer vs. Spassky | World Chess
  Championship 1972‎!
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      